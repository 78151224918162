.StyledCalendarWrapper {
    width: 1200px;
    min-height: 943px;
    display: flex;
    justify-content: center;
    position: relative;
}

.react-calendar {
    width: 100%;
    min-width: 1200px;
    border: none;
    border-radius: 0.5rem;
    /* box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.13); */
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
    padding: 20px;
    background-color: white;
}

.StyledCalendarWrapper.dark .react-calendar {
    background-color: #242424;
    box-shadow: 1px 1px 5px 2px #ffffff4d;
    color: #dddbdb;
}

/* 전체 폰트 컬러 */
.react-calendar__month-view {
    color: gray;
}

/* 네비게이션 가운데 정렬 */
.react-calendar__navigation {
    justify-content: center;
}

/* 네비게이션 폰트 설정 */
.react-calendar__navigation button {
    font-weight: 800;
    font-size: 1rem;
}

/* 네비게이션 버튼 컬러 */
.react-calendar__navigation button:focus {
    background-color: white;
}

/* 네비게이션 비활성화 됐을때 스타일 */
.react-calendar__navigation button:disabled {
    background-color: white;
    color: darkblue;
}

/* 년/월 상단 네비게이션 칸 크기 줄이기 */
.react-calendar__navigation__label {
    flex-grow: 0 !important;
}

/* 요일 밑줄 제거 */
.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
    font-weight: 800;
}

/* 일요일에만 빨간 폰트 */
.react-calendar__month-view__weekdays__weekday--weekend abbr[title="일요일"] {
    color: red;
}

/* 오늘 날짜 폰트 컬러 */
.react-calendar__tile--now {
    background: none;
    /* color: white; */
}

/* 네비게이션 월 스타일 적용 */
.react-calendar__year-view__months__month {
    border-radius: 0.8rem;
    /* background-color: gray; */
    padding: 0;
}

/* 네비게이션 현재 월 스타일 적용 */
.react-calendar__tile--hasActive {
    background-color: orange;
    color: white;
}

/* 일 날짜 간격 */
.react-calendar__tile {
    padding: 10px 0px;
    position: relative;
    height: 160px;
    border: 1px solid #fff !important;
    background-color: #f7f7f7 !important;
    display: flex;
    justify-content: center;
}

.StyledCalendarWrapper.dark .react-calendar__tile {
    background-color: #494848 !important;
    border: 1px solid #5f5e5e !important;
    color: #121214;
}

/* 네비게이션 월 스타일 적용 */
.react-calendar__year-view__months__month {
    flex: 0 0 calc(33.3333% - 10px) !important;
    /* margin-inline-start: 5px !important; */
    /* margin-inline-end: 5px !important; */
    margin-block-end: 10px;
    padding: 20px 6.6667px;
    font-size: 0.9rem;
    font-weight: 600;
    color: black;
}

.react-calendar__month-view__days {
    border: 1px solid rgb(243, 243, 243) !important;
}

.StyledCalendarWrapper.dark .react-calendar__month-view__days {
    border: 1px solid #5f5e5e !important;
}

/* 선택한 날짜 스타일 적용 */
/* .react-calendar__tile:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile--active:hover {
    background-color: #ffe2a8 !important;
    border: 1px solid #ffe2a8 !important;
    border-radius: 0.3rem;
    color: black;
} */

.react-calendar__tile:enabled:hover {
    background-color: rgb(216, 216, 216) !important;
}

.StyledCalendarWrapper.dark .react-calendar__tile:enabled:hover {
    background-color: #636363 !important;
}

.react-calendar__tile:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile--active:hover {
    color: black;
}

.react-calendar__tile--active abbr {
    background-color: #ffd37e !important;
}

.react-calendar__month-view__days__day abbr {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StyledDate {
    position: absolute;
    right: 7%;
    top: 30px;
    background-color: #F5AC1D;
    color: white;
    width: 100px;
    min-width: fit-content;
    height: 1.5rem;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6rem;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: 800;
    cursor: pointer;
}

.StyledType {
    position: absolute;
    left: 4%;
    top: 32px;
    width: 100px;
    min-width: fit-content;
    height: 1.5rem;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6rem;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.StyledType.dark {
    color: #121214;
}

.StyledType-1 {
    width: 50px;
    height: 17px;
    background-color: #fffbc3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.StyledType-2 {
    width: 50px;
    height: 20px;
    background-color: #c5e2f7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.StyledType-3 {
    width: 50px;
    height: 20px;
    background-color: #f7c5c5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.StyledToday {
    font-size: x-small;
    color: navy;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.StyledDot {
    background-color: navy;
    border-radius: 50%;
    width: 0.3rem;
    height: 0.3rem;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
}

.StyledEX {
    font-size: 11px;
    color: black;
    font-weight: 600;
    position: absolute;
    top: 50%;
    width: 140px;
    transform: translateY(-50%);
}

.StyledEX-1,
.StyledEX-2,
.StyledEX-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.StyledEX-1 {
    width: 100%;
    /* height: 14px; */
    background-color: #fffbc3;
    border-radius: 5px;
    margin-bottom: 5px;
}

.StyledEX-2 {
    width: 100%;
    height: 14px;
    background-color: #c5e2f7;
    border-radius: 5px;
    margin-bottom: 5px;
}

.StyledEX-3 {
    width: 100%;
    height: 14px;
    background-color: #f7c5c5;
    border-radius: 5px;
}