/* login */
.login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
    background-color: #fff;
    top: 0;
    left: 0;
}

.login.dark {
    background-color: #121212;
}

.login-box {
    width: 600px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-box img {
    width: 250px;
    margin-bottom: 40px;
}

.login-box-1 {
    width: 400px;
    height: 50px;
    border-radius: 5px;
    background-color: rgba(246, 246, 246, 1);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login-box-1:focus-within {
    box-shadow: rgb(245 172 29 / 20%) 0px 0px 24px;
    border: 1px solid rgb(245 172 29 / 25%);
    transition: all .2s ease-in-out;
}

.login-box-1.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.login-box-1 input {
    width: 350px;
    height: 45px;
    background-color: rgba(246, 246, 246, 1);
    border: none;
    outline: none;
    font-size: 15px;
}

.login-box-1.dark input {
    background: #494848;
    color: #fff;
}

.login-box-2 {
    width: 400px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-box-2-1 {
    width: 190px;
    height: 40px;
    border-radius: 5px;
    background-color: #F5AC1D;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login-box-2-1.dark {
    background: #494848;
    color: #fff;
}

.login-box-2-1:hover {
    background-color: rgb(250, 201, 117);
}

.login-box-2-1.dark:hover {
    background-color: #F5AC1D;
}

.login-box-2-2 {
    width: 190px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    color: #F5AC1D;
    border: 1px solid #F5AC1D;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login-box-2-2:hover {
    background-color: rgb(250, 201, 117);
    color: #fff;
}

.login-eye {
    cursor: pointer;
    font-size: 17px;
    color: #ccc;
    position: absolute;
    right: 20px;
}






/* verification */
.verification {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
    background-color: #fff;
    top: 0;
    left: 0;
}

.verification.dark {
    background-color: #121212;
}

.verification-box {
    width: 500px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.verification-box img {
    width: 200px;
    margin-bottom: 20px;
}

.verification-box-1 {
    font-size: 40px;
    font-weight: bold;
}

.verification-box-1.dark {
    color: #fff;
}

.verification-box-2 {
    font-size: 20px;
    color: rgba(114, 114, 112, 1);
}

.verification-box-2-1 {
    font-size: 17px;
    color: rgba(114, 114, 112, 1);
    margin-bottom: 20px;
    height: 40px;
}

.verification-box-2-2 {
    font-size: 17px;
    color: rgba(114, 114, 112, 1);
    margin-bottom: 20px;
    height: 40px;
    animation: resetOut .5s linear forwards;
}

.verification-box-2-1.on {
    animation: resetIn .5s linear forwards;
}

@keyframes resetIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes resetOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.verification-box-3 {
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 43, 255, 1);
    margin-bottom: 20px;
    text-decoration: underline;
}


/* passwordReset */
.reset-box {
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.reset-box.on {
    display: none;
}

.reset-box-1 {
    width: 300px;
    height: 50px;
    border-radius: 5px;
    background-color: rgba(246, 246, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-box-1:focus-within {
    box-shadow: rgb(245 172 29 / 20%) 0px 0px 24px;
    border: 1px solid rgb(245 172 29 / 25%);
    transition: all .2s ease-in-out;
}

.reset-box-1.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.reset-box-1 input {
    width: 230px;
    height: 45px;
    background-color: rgba(246, 246, 246, 1);
    border: none;
    outline: none;
    font-size: 15px;
}

.reset-box-1.dark input {
    background: #494848;
    color: #fff;
}

.reset-box-1-btn {
    width: 70px;
    height: 35px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 14px;
    cursor: pointer;
}

.reset-box-1-btn.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.reset-box-1-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.reset-box-2 {
    width: 500px;
    text-align: right;
    color: #F5AC1D;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 70px;
    margin-top: 10px;
}

.reset-box-2 span {
    cursor: pointer;
}

.reset-box-2 svg {
    padding-left: 2px;
    padding-top: 2px;
}

/* .reset-box-2-info{
    padding: 15px;
    border: 1px solid #F5AC1D;
    color: #F5AC1D;
    font-size: 14px;
    border-radius: 30px;
    margin-top: 10px;
} */