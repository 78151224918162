.App {
  min-width: 1000px;
  width: 100%;
  text-align: center;
  position: relative;
}

a{
  text-decoration: none;
}


/* animation: start .4s ease-out;

@keyframes start {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
} */