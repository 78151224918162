.admin-dashboard-1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* flex-direction: column; */
    gap: 20px;
}

.admin-dashboard-1-1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.admin-dashboard-3 {
    width: 650px;
    height: auto;
    min-height: 230px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.admin-dashboard-3.dark {
    background-color: #242424;
    box-shadow: 1px 1px 5px 2px #ffffff4d;
    color: #dddbdb;
}

.admin-dashboard-3-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 240px;
}

.admin-dashboard-5 {
    width: 650px;
    height: auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.admin-dashboard-5.dark {
    background-color: #242424;
    box-shadow: 1px 1px 5px 2px #ffffff4d;
    color: #dddbdb;
}

.admin-ref {
    width: 200px;
    height: 105px;
    padding: 16px;
    border: 1px solid #dddddd;
    border-radius: 15px;
    position: relative;
}

.admin-ref.dark {
    border: 1px solid #3f3e3e;
}

.admin-ref .plus {
    cursor: pointer;
    font-size: 30px;
    color: gray;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.admin-ref-1 {
    font-size: 20px;
    text-align: left;
    padding-bottom: 12px;
}

.admin-ref-2 {
    font-size: 12px;
}

.admin-ref-2-1 {
    height: 35px;
    width: 100%;
    margin: 0 auto;
    padding: 0 7px;
    background-color: #ebebeb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.admin-ref-2-1.dark {
    background: #494848;
    color: #fff;
}

.admin-ref-2-1-1 {}

.admin-ref-2-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-ref-2-2-1 {
    width: 50%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid rgb(216, 216, 216);
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 7px;
}

.stay-settlement {
    width: 50% !important;
    cursor: pointer;
}

.stay-settlement:hover {
    color: red;
}