/* header */
.header {
    width: 100%;
    height: 65px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.161);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #fff;
}

.header.dark {
    box-shadow: 0px 2px 3px rgba(255, 255, 255, 0.161);
    background-color: #121212;
    color: #fff;
}

.header-box {
    width: 100%;
    height: 100%;
    display: flex;
}

.header-left {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-left a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-left img {
    padding-top: 5px;
    width: 130px;
    cursor: pointer;
}

.header-right {
    width: calc(100% - 200px);
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-right-alert {
    position: absolute;
    left: 40px;
    width: 150px;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.header-right-alert.alert {
    background-color: #F5AC1D;
    color: #fff;
}

.header-right-alert.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.header-right-alert.dark.alert {
    background-color: #F5AC1D;
    color: #fff;
}

.header-right-alert:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.header-right-1 {
    position: absolute;
    width: auto;
    height: 100%;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    font-size: 14px;
}

.header-right-extension {
    width: 150px;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    gap: 7px;
    cursor: pointer;
}

.header-right-extension.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.header-right-extension svg {
    color: #6b6969;
}

.header-right-extension.dark svg {
    color: #a19f9f;
}

.header-right-extension:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.header-right-extension:hover svg {
    color: #fff;
}

.header-right-logout {
    width: 80px;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.header-right-logout.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.header-right-logout:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.toggle-menu-sun-icon {
    color: #F5AC1D;
    font-size: 20px;
    cursor: pointer;
}

.toggle-menu-moon-icon {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-right: 5px;
}




/* sidebar */
.sidebar-box-1 .cls-1,
.sidebar-box-1 .cls-2,
.sidebar-box-1 .cls-3 {
    fill: #707070;
}

.sidebar-box-1 .cls-1,
.sidebar-box-1 .cls-2,
.sidebar-box-1 .cls-3 {
    fill: #bdbdbd;
}

.sidebar-box-1 .cls-4 {
    fill: inherit;
}

.sidebar {
    width: calc(200px - 20px);
    height: calc(100% - 65px);
    padding: 65px 10px 0;
    z-index: 2;
    background-color: rgba(246, 246, 246, 1);
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid rgba(246, 246, 246, 1);
}

.sidebar.dark {
    background: #242424;
    color: #fff;
    border-right: 1px solid #2f2f2f;
}

.sidebar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 10px);
    margin: 0 auto;
    font-size: 14px;
    color: gray;
    cursor: pointer;
}

.sidebar-box {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar-box-1 {
    width: calc(100% - 40px);
    padding: 0 20px 0 20px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    cursor: pointer;
    color: #707070;
    border-radius: 5px;
}

.sidebar-box-1.dark {
    color: #bdbdbd;
}

.sidebar-box-1 svg {
    width: 19px;
}

.sidebar-box-1.on {
    color: #F5AC1D;
    transition: all 0.2s;
    background-color: #fff;
}

.sidebar-box-1.on.dark {
    background: #494848;
}

.sidebar-box-1.on .cls-1,
.sidebar-box-1.on .cls-3 {
    fill: #F5AC1D;
}

.sidebar-box-1.on .cls-4 {
    fill: #fff;
}

.sidebar-box-1:hover {
    color: #F5AC1D;
    transition: all 0.2s;
    background-color: #fff;
}

.sidebar-box-1.dark:hover {
    background: #494848;
}

.sidebar-box-1:hover .cls-1,
.sidebar-box-1:hover .cls-3 {
    fill: #F5AC1D;
}

.sidebar-box-1:hover .cls-4 {
    fill: #fff;
}

.sidebar-box-2 {
    width: 100%;
    height: auto;
    gap: 15px;
}

.sidebar-box-1-1 {
    font-size: 16px;
    font-weight: bold;
}

.sidebar-box-1-2 {
    font-size: 14px;
    color: #707070;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    margin-top: 5px;
    gap: 2px;
}

.sidebar-box-1-2 div {
    width: calc(100% - 65px);
    height: 25px;
    cursor: pointer;
    color: #707070;
    padding-left: 55px;
    display: flex;
    align-items: center;
}

.sidebar-box-1-2 div:hover {
    transition: all 0.2s;
    background-color: #fff;
    border-radius: 5px;
    color: #F5AC1D;
}

.sidebar-box-1-2.dark div:hover {
    background-color: #494848;
}

.sidebar-box-1-2 div.on {
    transition: all 0.2s;
    background-color: #fff;
    border-radius: 5px;
    color: #F5AC1D;
}

.sidebar-box-1-2.dark div.on {
    background-color: #494848;
}




/* footer */
.footer {
    width: 100%;
    height: 35px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #dcdee0;
    z-index: 2;
    background-color: #fff;
}

.footer.dark {
    background-color: #121212;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.footer-box {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-box-1 {
    font-size: 12px;
}