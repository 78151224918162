.main-box.dark .apexcharts-legend-text {
    position: relative;
    font-size: 14px;
    color: #fff !important;
}

.react-datepicker__input-container input {
    width: 75px;
    height: 10px;
    border: none;
    outline: none;
    background: inherit;
    cursor: pointer;
    text-align: center;
    color: rgba(136, 136, 136, 1);
    padding: 11px 12px;
    font-size: 14px;
}

.modal-3-1-left.dark .react-datepicker__input-container input {
    color: #fff;
}

.modal-3-1-left-1.on .react-datepicker__input-container input {
    color: #fff;
}

.modal-3-1-left-1:hover .react-datepicker__input-container input {
    color: #fff;
}

.bulletin-1-1-tit.dark .react-datepicker__input-container input {
    color: #fff;
}

.bulletin-1-1-tit.on .react-datepicker__input-container input {
    color: #fff;
}

.bulletin-1-1-tit:hover .react-datepicker__input-container input {
    color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #F5AC1D !important;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

.data-nofound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.data-nofound img {
    width: 80px;
}

.data-nofound p {
    opacity: .5;
    font-size: 14px;
    margin: 0;
}














/* dashboard */
.dashboard-box {
    width: 100%;
    min-width: 1400px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 50px;
}

.dashboard-1 {
    width: 50%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.dashboard-2 {
    width: 50%;
    height: auto;
    min-height: 1099px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.dashboard-2.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.dashboard-3 {
    width: 100%;
    height: auto;
    min-height: 340px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.dashboard-3.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.dashboard-4 {
    width: 100%;
    height: auto;
    min-height: 362px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.dashboard-4.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.dashboard-5 {
    width: 100%;
    height: auto;
    min-height: 355px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.dashboard-5.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.dashboard-6 {
    width: 100%;
    height: auto;
    min-height: 362px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161);
}

.dashboard-6.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.dashboard-3-1 {
    width: calc(100% - 40px);
    height: 32px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.dashboard-3-1-1 {
    font-weight: bold;
    font-size: 14px;
    color: rgba(99, 99, 99, 1);
}

.dashboard-3-1-1.dark {
    color: #dddbdb;
}

.dashboard-3-1-2 {
    padding: 6px 12px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}

.dashboard-3-1-2.dark {
    background-color: #494848;
    color: #fff;
}

.dashboard-3-1-2.on {
    background-color: #F5AC1D;
    color: #fff;
}

.dashboard-3-1-2:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.dashboard-3-2 {
    width: 100%;
    height: auto;
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-3-3 {
    width: calc(100% - 20px);
    height: 240px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.dashboard-3-3-1 {
    width: 100%;
    height: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.dashboard-3-3-2 {
    width: 50%;
    height: 100%;
    position: relative;
    box-shadow: 1px 1px 2px 2px #85858529;
    border-radius: 5px;
}

.dashboard-3-3-2.dark {
    background: #494848;
    box-shadow: 1px 1px 2px 2px #bdbcbc29;
}

.dashboard-3-3-3 {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: bold;
    font-size: 14px;
    color: rgba(112, 112, 112, 1);
}

.dashboard-3-3-3.dark {
    color: #dddbdb;
}

.dashboard-3-3-4 {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.dashboard-3-3-4 span {
    font-size: 12px;
}

.dashboard-4-1 {
    width: calc(100% - 40px);
    height: 32px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    position: relative;
}

.dashboard-4-1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.dashboard-4-1-1-tit {
    font-size: 15px;
    color: rgba(99, 99, 99, 1);
}

.dashboard-4-1-1-tit.dark {
    color: #dddbdb;
}

.dashboard-4-1-1-tit span {
    font-size: 15px;
    color: #F5AC1D;
}

.dashboard-4-2 {
    position: absolute;
    right: 20px;
    /* top: 50%;
    transform: translateY(-50%); */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.dashboard-4-3 {
    width: calc(100% - 30px);
    height: auto;
    padding: 0px 15px;
    position: relative;
}

.withdraw-btn {
    width: auto !important;
    height: auto !important;
    padding: 2px 4px;
    box-shadow: 1px 1px 1px 1px #dbdbdb;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    font-size: 11px !important;
    cursor: pointer;
}

.withdraw-btn.dark {
    color: #fff;
    box-shadow: 1px 1px 1px 1px #4e4e4e;
    border: 1px solid #3f3e3e;
}

.withdraw-btn:hover {
    background-color: #ececec;
}

.withdraw-btn.dark:hover {
    background-color: #494848;
}

.dashboard-2-1 {
    padding: 20px 10px;
}

.dashboard-2-2 {
    width: calc(100% - 30px);
    height: auto;
    padding: 0px 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.dashboard-2-2-1 {
    width: 100%;
    height: 50px;
    position: relative;
    box-shadow: 1px 1px 2px 2px #85858529;
    border-radius: 5px;
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
    flex-direction: column;
    padding: 7px 10px;
    font-size: 14px;
    gap: 5px;
}

.dashboard-2-2-1.dark {
    background: #494848;
    box-shadow: 1px 1px 2px 2px #bdbcbc29;
}

.dashboard-2-2-1-tit {
    font-weight: bold;
    color: #000000;
    /* color: rgba(112, 112, 112, 1); */
}

.dashboard-2-2-1-tit.dark {
    color: #dddbdb;
}

.dashboard-2-3 {
    width: calc(100% - 30px);
    height: auto;
    padding: 0px 15px 20px;
}

.apexcharts-toolbar {
    display: none !important;
}

.dashboard-5-box {
    background: #fff;
    position: absolute;
    z-index: 10;
    width: calc(100% - 30px);
    height: 100%;
}

.dashboard-5-box.dark {
    background: #242424;
}

.dashboard-5-1 {
    width: 100%;
    padding: 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-5-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.dashboard-5-1-1 {
    padding: 3px 8px;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #e7e7e7;
}

.dashboard-5-1-1.dark {
    background-color: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.dashboard-2-2-1 span {
    font-size: 12px;
}





















/* main */
.main {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    position: relative;
}

.main-box {
    width: calc(100% - 280px);
    height: calc(100% - 85px);
    padding-top: 85px;
    padding-left: 240px;
    padding-right: 40px;
    min-width: 1620px;
    min-height: calc(100vh - 85px);
}

.main-box.dark {
    background-color: #121212;
    color: #fff;
}



/* member */
.member-1 {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.member-1-search {
    width: 235px;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.member-1-search.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.member-1-search input {
    width: 170px;
    height: 30px;
    background-color: #fff;
    border: none;
    outline: none;
}

.member-1-search.dark input {
    background: #494848;
    color: #fff;
}

.member-1-search-ico-1 {
    font-size: 15px;
    position: absolute;
    left: 10px;
}

.member-1-search-ico-2 {
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #a1a0a0;
    cursor: pointer;
}

.search-btn {
    padding: 0px 10px;
    height: 32px;
    font-size: 13px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
}

.search-btn.dark {
    background: #494848;
    border: 1px solid #494848;
    color: #fff;
}

.search-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.member-1-select {
    width: 160px !important;
    height: 32px !important;
    border-radius: 5px;
    background-color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.member-2-select {
    width: 100px !important;
    height: 25px !important;
    border-radius: 5px;
    background-color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.member-1-select.dark,
.member-2-select.dark {
    background: #494848;
    color: #fff;
}

.member-1-select.border,
.member-2-select.border {
    border: 1px solid #e9e9e9;
}

.member-1-select.border.dark,
.member-1-select.border.dark {
    border: 1px solid #3f3e3e;
}

.member-1-select-1 {
    width: calc(100% - 15px) !important;
    text-align: left;
    padding-left: 5px;
    font-size: 14px;
    justify-content: flex-start !important;
    border: none !important;
}

.member-1-select svg,
.member-2-select svg {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b8b8b;
}

.member-1-select-box {
    position: absolute;
    width: 150px !important;
    height: auto !important;
    top: 33px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    /* animation: member_drowdown .5s ease-in-out; */
    z-index: 21;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
}

.member-2-select-box {
    position: absolute;
    width: 90px !important;
    height: auto !important;
    top: 33px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    /* animation: member_drowdown .5s ease-in-out; */
    z-index: 21;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
}

.member-3-select-box {
    position: absolute;
    width: calc(100% - 15px) !important;
    height: auto !important;
    top: 44px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    /* animation: member_drowdown .5s ease-in-out; */
    z-index: 21;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
    left: 50%;
    transform: translateX(-50%);
}

.member-1-select-box.dark,
.member-2-select-box.dark,
.member-3-select-box.dark,
.dashboard-1-select-box.dark,
.dashboard-2-select-box.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.member-1-select-box div,
.member-2-select-box div,
.member-3-select-box div,
.dashboard-1-select-box div,
.dashboard-2-select-box div {
    text-align: left;
    padding: 8px 12px !important;
    font-size: 14px !important;
    width: calc(100% - 24px) !important;
    justify-content: flex-start !important;
    border: none !important;
}

.member-1-select-box div:hover,
.member-2-select-box div:hover,
.member-3-select-box div:hover,
.dashboard-1-select-box div:hover,
.dashboard-2-select-box div:hover {
    background-color: #e9e9e9;
}

.member-1-select-box.dark div:hover,
.member-2-select-box.dark div:hover,
.member-3-select-box.dark div:hover,
.dashboard-1-select-box.dark div:hover,
.dashboard-2-select-box.dark div:hover {
    background-color: #5e5e5e;
}

.dashboard-1-select-box {
    position: absolute;
    width: 93px !important;
    height: auto !important;
    top: 33px;
    left: 0;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    /* animation: member_drowdown .5s ease-in-out; */
    z-index: 21;
    flex-direction: column;
    align-items: flex-start !important;
    color: #121212;
}

.dashboard-2-select-box {
    position: absolute;
    width: 72px !important;
    height: auto !important;
    top: 33px;
    left: 0;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 5px;
    /* animation: member_drowdown .5s ease-in-out; */
    z-index: 21;
    flex-direction: column;
    align-items: flex-start !important;
    color: #121212;
}

@keyframes member_drowdown {
    from {
        opacity: 0.9;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.member-2-search {
    width: 380px;
    height: 32px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.member-2-search.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.member-2-search-1 {
    width: 200px;
    height: 32px;
    /* border: 1px solid #F5AC1D; */
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    position: relative;
}

.member-2-search-1.dark {
    background: #494848;
    color: #fff;
}

.member-2-search-1 input {
    width: 170px;
    height: 30px;
    background-color: #fff;
    border: none;
    outline: none;
}

.member-2-search-1.dark input {
    background: #494848;
    color: #fff;
}

.member-1-search-ico-3 {
    font-size: 15px;
    position: absolute;
    left: -5px;
}

.line {
    transform: rotate(90deg);
    font-size: 22px;
    color: #dbdbdb;
}

.member-2 {
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 50px;
}

.member-2.dark {
    color: #dddbdb;
}

.table-head {
    padding-top: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #000000;
    /* border-bottom: 2px solid rgba(237, 239, 241, 1); */
}

.table-head svg {
    cursor: pointer;
}

.table-head.dark {
    border-bottom: 2px solid #3f3e3e;
}

.table-head div {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    /* color: #636363; */
    position: relative;
    padding: 0 10px;
}

.table-head-dash {
    /* padding-top: 10px; */
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #000000;
    /* border-bottom: 2px solid rgba(237, 239, 241, 1); */
}

.table-head-dash.dark {
    border-bottom: 2px solid #3f3e3e;
}

.table-head-dash div {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    /* color: #636363; */
    padding: 0 10px;
}


.table-body {
    width: 100%;
    height: auto;
    position: relative;
}

.table-body-tr {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(237, 239, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.table-body-tr.dark {
    border-bottom: 1px solid #3f3e3e;
    color: #bdbdbd;
}

.table-body-tr-1 {
    width: 30%;
    height: 30px;
    border-right: 1px solid rgba(237, 239, 241, 1);
}

.table-body-tr-1.dark {
    border-right: 1px solid #3f3e3e;
}

.table-body-tr .td {
    font-size: 13px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-right: 1px solid rgba(237, 239, 241, 1);
    box-sizing: border-box;
}

.table-body-tr .td:first-child {
    border-left: 1px solid rgba(237, 239, 241, 1);
}

.table-body-tr.dark .td {
    border-right: 1px solid #3f3e3e;
}

.table-body-tr.dark .td:first-child {
    border-left: 1px solid #3f3e3e;
}

.overflow-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    line-height: 45px;
    cursor: pointer;
}

.overflow-box {
    position: absolute;
    top: 10px;
    /* right: 20%; */
    /* left: 50%;
    transform: translateX(-50%); */
    border: 1px solid #cfcdcd;
    background-color: #f3f3f3;
    border-radius: 3px;
    padding: 5px 15px;
    z-index: 1;
    white-space: wrap;
    word-wrap: break-word;
    overflow: visible !important;
    max-width: 70vw;
    height: 30px;
    line-height: 30px;
}

.overflow-box.dark {
    background-color: #2a2c37;
    border: 1px solid #cccccc16;
}

.table-body-tr div input {
    font-size: 14px;
    width: 80%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    text-align: center;
    background-color: inherit;
    color: inherit;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
}

.table-body-tr-dash {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid rgba(237, 239, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-body-tr-dash.dark {
    border-bottom: 1px solid #3f3e3e;
    color: #bdbdbd;
}

.table-body-tr-dash div {
    font-size: 13px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(237, 239, 241, 1);
    padding: 0 10px;
}

.table-body-tr-dash div:first-child {
    border-left: 1px solid rgba(237, 239, 241, 1);
}

.table-body-tr-dash.dark div {
    border-right: 1px solid #3f3e3e;
}

.table-body-tr-dash.dark div:first-child {
    border-left: 1px solid #3f3e3e;
}

.onclick {
    cursor: pointer;
}

.onclick:hover {
    color: #F5AC1D;
    text-decoration: underline;
}

.member-input {
    margin-left: 5px;
    border: none;
    outline: none;
    background-color: inherit;
    color: inherit;
}

.member-country-input {
    width: 75px;
    padding: 3px 0;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-left: 5px;
    border-right: 1px solid rgb(219, 219, 219);
}

.signup_password_modal {
    width: 50%;
    position: absolute;
    top: 45px;
    right: -1px;
    z-index: 100;
    background: #fff;
    padding: 16px;
    padding-bottom: 11px;
    border-radius: 15px;
    box-shadow: rgb(18 18 18 / 8%) 0px 5px 12px;
    max-width: 275px;
}

.signup_password_modal.dark {
    background: #242424;
    color: #fff;
    border: 1px solid #2f2f2f;
}

.signup_password_condition {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
}

.signup_password_condition_check {
    width: 14px;
    height: 14px;
    background-size: 11px;
    padding-right: 10px;
    color: #ccc;
}

.signup_password_condition_check.on {
    color: #F5AC1D;
}

.spinner-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-modal {
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    text-align: left;
}

.message-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-size: 17px;
    padding: 25px;
    z-index: 10000;
    box-shadow: 1px 1px 4px 1px #656565;
    background-color: #fff;
    border-radius: 5px;
}

.message-modal-2 {
    padding-bottom: 25px;
}

.message-modal-btn {
    width: 100%;
    border: 1px solid #b1b1b1;
    background-color: #d5d5d5;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
}

.message-modal-btn:hover {
    background-color: #a3a2a2;
}





/* paging */
.main-paging {
    text-align: right;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    padding: 20px 24px;
    width: calc(100% - 48px);
}

.main-paging-pages>span {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
}

.main-paging-pages>span:hover {
    background-color: #d7d7d7;
    color: #121212;
}

.main-paging-pages.dark>span {
    color: #fff;
    border: 1px solid #444444;
}

.main-paging-pages.dark>span:hover {
    background-color: #d7d7d7;
    color: #121212;
}

.main-paging-btn {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
}

.main-paging-btn.dark {
    border: 1px solid #444444;
    color: #fff;
}

.main-paging-pages>span.active {
    background-color: #d7d7d7;
    font-weight: bold;
}

.main-paging-pages.dark>span.active {
    background-color: #d7d7d7;
    font-weight: bold;
    color: #121212;
}























/* modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
}

.modal-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-1 {
    width: 1000px;
    /* height: 400px; */
    background-color: #fff;
    border: 1px solid #d5dae0;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 1px #656565;
    padding: 50px;
}

.modal-1-tit {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    /* border-bottom: 1px solid #e4e3e3; */
    padding-bottom: 5px;
}

.modal-1-tit.dark {
    color: #fff;
}

.modal-1-1 {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.modal-1-1-1 {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid rgba(237, 239, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-1-1-1-tit {
    width: 15%;
    font-size: 17px;
    text-align: left;
    color: #F5AC1D;
    font-weight: bold;
}

.modal-1-1-1-txt {
    width: 35%;
    font-size: 15px;
    text-align: left;
    color: #444444;
}

.modal-1-1-2-tit {
    width: 15%;
    font-size: 17px;
    text-align: left;
    color: #F5AC1D;
    font-weight: bold;
}

.modal-1-1-2-txt {
    width: 85%;
    font-size: 15px;
    text-align: left;
    color: #444444;
}

.modal-1-2 {
    width: 100%;
    height: 60px;
    background: rgba(239, 239, 239, 1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-1-2-box {
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
}

.modal-1-2-1 {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: rgba(112, 112, 112, 1);
    font-weight: bold;
}

.modal-1-2-2 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-1-2-2-btn {
    padding: 5px 10px;
    background: #fff;
    border-radius: 5px;
    color: black;
    border: 1px solid #F5AC1D;
    font-size: 14px;
}

.modal-1-2-2-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-1-3 {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}

.modal-1-3-1 {
    padding: 7px 20px;
    background: #fff;
    border-radius: 8px;
    color: black;
    border: 1px solid #F5AC1D;
    font-size: 15px;
    cursor: pointer;
}

.modal-1-3-1.complete {
    background: #adadad;
    color: #fff;
    border: 1px solid #adadad;
}

.modal-1-3-1:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-1-3-1.complete:hover {
    background-color: #adadad;
    color: #fff;
}

.modal-1-4 {
    width: 100%;
    height: auto;
    margin-top: 20px;
}

.modal-2 {
    width: 1200px;
    height: 380px;
    background-color: #fff;
    border: 1px solid #d5dae0;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 1px #656565;
    padding: 30px;
}

.modal-2.dark {
    background-color: #242424;
    box-shadow: 1px 1px 20px 1px #757575;
    border: 1px solid #3f3e3e;
}

.modal-2-1 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-2-1-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
}

.modal-2-1-left-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.modal-2-1-left-box-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    /* padding-left: 122px; */
}

.modal-2-1-left-1 {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    font-size: 14px;
}

.modal-2-1-right {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.modal-2-1-right-box {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-left: 10px;
}

.modal-2-1-right-box.dark {
    border: 1px solid #3f3e3e;
}

.modal-2-1-right-box .modify {
    padding: 8px 7px;
    background-color: #dbdbdb;
    justify-content: end;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.modal-2-1-right-box.dark .modify {
    background-color: #434343;
}

.modal-2-1-right-box .modify:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-2-2 {
    width: 100%;
    height: auto;
    margin-bottom: 20px
}

.modal-2-3 {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.modal-2-3-btn {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dbdbdb;
    border-radius: 5px;
    cursor: pointer;
}

.modal-2-3-btn.dark {
    background-color: #494848;
}

.modal-2-3-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-3 {
    width: 1350px;
    /* height: 700px; */
    background-color: #fff;
    border: 1px solid #d5dae0;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 1px #656565;
    padding: 30px;
}

.modal-3.dark {
    background-color: #242424;
    box-shadow: 1px 1px 20px 1px #757575;
    border: 1px solid #3f3e3e;
}

.modal-3-1 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.modal-3-1-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.modal-3-1-left-1 {
    padding: 6px 12px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.modal-3-1-left.dark .modal-3-1-left-1 {
    background: #494848;
    color: #fff;
}

.modal-3-1-left .modal-3-1-left-1.on {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-3-2 {
    width: 100%;
    height: 530px;
}

.modal-3-1-left .modal-3-1-left-1:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-4 {
    width: 350px;
    height: 280px;
    background-color: #fff;
    border: 1px solid #d5dae0;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 1px #656565;
    padding: 30px;
}

.modal-4.dark {
    background-color: #242424;
    box-shadow: 1px 1px 20px 1px #757575;
    border: 1px solid #3f3e3e;
}

.modal-5 {
    width: 350px;
    background-color: #fff;
    border: 1px solid #d5dae0;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 1px #656565;
    padding: 30px;
}

.modal-5.dark {
    background-color: #242424;
    box-shadow: 1px 1px 20px 1px #757575;
    border: 1px solid #3f3e3e;
}
















.password {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.password-1 {
    width: calc(100% - 30px);
    height: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 12px 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.password-1.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.password-1:focus-within {
    box-shadow: rgb(245 172 29 / 20%) 0px 0px 24px;
    border: 1px solid rgb(245 172 29 / 25%);
    transition: all .2s ease-in-out;
}

.password-1 input {
    width: 100%;
    height: 20px;
    border: none;
    outline: none;
    font-size: 15px;
    background-color: inherit;
}

.password-1.dark input {
    background: #494848;
    color: #fff;
}

.password-2 {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.password-2-1 {
    width: 165px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(239, 239, 239, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.password-2-1.dark {
    background-color: #494848;
}

.password-2-1.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.password-2-1:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.modal-2-1-left-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.modal-1-tit-1 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    position: relative;
}

.modal-x {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    right: 0;
    cursor: pointer;
}

.modal-1-tit-box {
    padding: 3px 8px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #e7e7e7;
}

.modal-1-tit-box.dark {
    border: 1px solid #3f3e3e;
    background-color: #434343;
}

.hr {
    margin: 20px 0 30px 0;
    border-top: 1px solid #dbdbdb;
    width: 100%;
}

.hr.dark {
    border-top: 1px solid #3f3e3e;
}

.sidebar-hr {
    margin: 5px 0;
    border-top: 2px dashed #b6b6b6;
    width: 100%;
}

.modal-2-1-left-3 {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.modal-2-1-left-3.dark {
    border: 1px solid #3f3e3e;
}

.modal-2-1-left-3:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.verification-btn {
    padding: 5px 10px;
    font-size: 14px;
    background-color: rgba(239, 239, 239, 1);
    border-radius: 10px;
    cursor: pointer;
}

.verification-btn.dark {
    background: #898686;
    color: #fff;
}

.verification-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.password-eye {
    cursor: pointer;
    font-size: 17px;
    color: #ccc;
}

















/* withdraw */
.withdraw-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    height: 32px;
}

.withdraw-1.dark {
    color: #dddbdb;
}

.withdraw-2 {
    margin: 30px 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.withdraw-2.dark {
    color: #fff;
}

.withdraw-2 div {
    padding: 0 10px;
    padding-bottom: 3px;
    font-size: 14px;
    cursor: pointer;
}

.withdraw-2 div.on {
    color: #F5AC1D;
    border-bottom: 1px solid #F5AC1D;
}

.withdraw-3 {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
}

.withdrawitem-btn {
    width: 70px !important;
    height: 20px !important;
    padding: 2px 4px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    font-size: 12px !important;
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px !important;
}

.withdrawitem-btn:hover {
    background-color: #ececec;
}

.withdrawitem-btn.dark {
    color: #fff;
    box-shadow: 1px 1px 1px 1px #4e4e4e;
    border: 1px solid #3f3e3e;
}

.withdrawitem-btn.dark:hover {
    color: #494848 !important;
}

.width-40 {
    width: 40px !important;
}
























/* trading */
.trading-1 {
    width: 100%;
    max-width: 1100px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trading-1-1 {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trading-1-2 {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.trading-1-2-box {
    width: 225px;
    height: 125px;
    box-shadow: 1px 1px 2px 2px #85858529;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.trading-1-2-box.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.trading-1-2-box-1 {
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 17px;
    /* color: #F5AC1D; */
    font-weight: bold;
}

.trading-1-2-box-2 {
    position: absolute;
    bottom: 15px;
    right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #121212;
}

.trading-1-2-box-2.dark {
    color: #ddd;
}

.trading-1-2-box-2 span {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 500;
}

.tradingType-1 {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 30px 0 10px;
    position: relative;
}

.tradingType-1-1 {
    width: 100px;
    height: 32px;
    background-color: rgba(237, 239, 241, 1);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tradingType-1-1.dark {
    background: #494848;
    color: #fff;
}

.tradingType-1-1.on {
    background-color: #F5AC1D;
    color: #fff;
}

.tradingType-1-2 {
    width: 130px;
    height: 32px;
    background-color: rgba(237, 239, 241, 1);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
}

.tradingType-1-2.dark {
    background-color: #494848;
    color: #fff;
}

.tradingType-1-2:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.apexcharts-legend-series {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
}

.tradingType-2 {
    width: 100%;
    padding-bottom: 50px;
}
























/* setting */
.setting-1 {
    width: 100%;
    height: auto;
}

.setting-2 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0 10px;
    position: relative;
}

.setting-2-1 {
    width: 130px;
    height: 32px;
    background-color: rgba(237, 239, 241, 1);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-2-1.dark {
    background: #494848;
    color: #fff;
}

.setting-2-2 {
    width: 32px;
    height: 32px;
    background-color: rgba(237, 239, 241, 1);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-2-2.dark {
    background: #494848;
    color: #fff;
}

.setting-2-1:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.setting-2-2:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.setting-3 {
    width: 100%;
    height: auto;
}

.table-input {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    height: 28px;
    outline: none;
    text-align: center;
}

.table-input.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.setting-4 {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.setting-4-btn {
    width: 150px;
    height: 35px;
    border-radius: 5px;
    background-color: rgba(237, 239, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.setting-4-btn.dark {
    background: #494848;
    color: #fff;
}

.setting-4-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.cslog-1 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 0 0;
    position: relative;
}

.profile-1 {
    width: 100%;
    height: auto;
    text-align: left;
}

.profile-1-1 {
    font-size: 15px;
    color: rgba(112, 112, 112, 1);
    font-weight: bold;
    margin-bottom: 20px;
}

.profile-1-2 {
    width: calc(450px - 40px);
    height: 55px;
    padding: 0 20px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-1-2.dark {
    color: #fff;
    border: 1px solid #3f3e3e;
}

.profile-1-2-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.profile-1-2-1 svg {
    color: #737373;
}

.profile-1-2-2-btn {
    padding: 4px 8px;
    border-radius: 5px;
    background-color: rgba(237, 239, 241, 1);
    font-size: 15px;
    cursor: pointer;
}

.profile-1-2-2-btn.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.profile-1-2-2-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}















/* bulletin */
.bulletin-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.bulletin-1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.bulletin-1-1-tit {
    padding: 6px 12px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.bulletin-1-1-tit.dark {
    background: #494848;
    color: #fff;
}

.bulletin-1-1-tit.on {
    background-color: #F5AC1D;
    color: #fff;
}

.bulletin-1-1-tit:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.bulletin-2 {
    width: 100%;
    height: 1000px;
    /* box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161); */
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 35px;
    flex-wrap: wrap;
}

.bulletin-2.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.bulletin-2-1 {
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    gap: 5px;
}

.bulletin-2-1 img {
    width: 290px;
    height: 290px;
}

.bulletin-2-2 {
    width: 270px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.161);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
}

.bulletin-2-2.dark {
    background: #494848;
    color: #fff;
}

.bulletin-2-2-1 {
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
}

.bulletin-2-2-1.dark {
    color: #fff;
}

.bulletin-2-2-2 {
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: rgba(136, 136, 136, 1);
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.bulletin-2-2-2.dark {
    color: #fff;
}

.bulletin-2-2-3 {
    width: 100%;
    font-size: 10px;
    color: rgba(136, 136, 136, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bulletin-2-2-3.dark {
    color: #fff;
}

.bulletin-2-hover {
    width: 290px;
    height: 290px;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.bulletin-2-hover-1 {
    width: 240px;
    height: 50px;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(237, 239, 241, 1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bulletin-2-hover-1:hover {
    background-color: #c1c1c1;
}

.bulletin-2-public {
    width: 270px;
    height: 35px;
    z-index: 1;
    background-color: rgba(0, 149, 3, 0.5);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    color: #fff;
    font-size: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.bulletin-2-privacy {
    width: 270px;
    height: 35px;
    z-index: 1;
    background-color: rgba(255, 0, 0, 0.4);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    color: #fff;
    font-size: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.bulletin-3 {
    width: 850px;
    height: auto;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.161);
    border-radius: 5px;
    padding: 18px;
    font-size: 14px;
}

.bulletin-3.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.bulletin-3-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(237, 239, 241, 1);
}

.bulletin-3-1.dark {
    border-bottom: 1px solid #3f3e3e;
}

.bulletin-3-1-1 {
    padding: 6px 12px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.bulletin-3-1-1.dark {
    background: #494848;
    color: #fff;
}

.bulletin-3-1-1:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.bulletin-3-2 {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
    flex-direction: column;
}

.bulletin-3-2-1 {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.bulletin-3-2-1-tit {
    width: 170px;
    padding: 2px 0 2px 10px;
}

.bulletin-3-2-1-txt {
    width: calc(100% - 170px);
    padding: 2px 0;
    border-bottom: 1px solid rgba(237, 239, 241, 1);
}

.bulletin-3-2-1-txt.dark {
    border-bottom: 1px solid #3f3e3e;
}

.bulletin-3-2-1-txt input {
    width: 100%;
    background-color: inherit;
    border: none;
    outline: none;
}

.bulletin-3-2-2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 5px 0;
}

.bulletin-3-2-2-tit {
    width: 170px;
    padding: 2px 0 2px 10px;
}

.bulletin-3-2-2-txt {
    width: calc(100% - 170px);
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.bulletin-3-2-2-btn {
    width: 50px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid rgb(221 221 221);
    background-color: rgba(237, 239, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px 5px;
}

.bulletin-3-2-2-btn.dark {
    background: #494848;
    color: #fff;
    border: 1px solid #3f3e3e;
}

.bulletin-3-2-2-btn:hover {
    background-color: #F5AC1D;
    color: #fff;
}

.bulletin-3-2-2-box {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(237, 239, 241, 1);
}

.bulletin-3-2-2-box.dark {
    border: 1px solid #3f3e3e;
}

.bulletin-3-2-3 {
    width: calc(100% - 170px);
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.bulletin-3-2-3-tit {
    padding: 5px 10px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.bulletin-3-2-3-tit.dark {
    background: #494848;
    color: #fff;
}

.bulletin-3-2-4 {
    width: calc(100% - 170px);
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(237, 239, 241, 1);
}

.bulletin-3-2-4.dark {
    border: 1px solid #3f3e3e;
}

.bulletin-4 {
    width: 100%;
    height: 1000px;
    /* box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.161); */
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 35px;
    flex-wrap: wrap;
}

.bulletin-4.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.bulletin-4-1 {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.bulletin-4-1 img {
    width: 200px;
    height: 200px;
}

.bulletin-4-2 {
    width: 180px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.161);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
}

.bulletin-4-2.dark {
    background: #494848;
    color: #fff;
}

.bulletin-4-2-1 {
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
}

.bulletin-4-2-1.dark {
    color: #fff;
}

.bulletin-4-2-2 {
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: rgba(136, 136, 136, 1);
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.bulletin-4-2-2.dark {
    color: #fff;
}

.bulletin-4-2-3 {
    width: 100%;
    font-size: 10px;
    color: rgba(136, 136, 136, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bulletin-4-2-3.dark {
    color: #fff;
}

.bulletin-4-end {
    width: 200px;
    height: 200px;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.bulletin-4-end-1 {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background-color: rgba(36, 36, 36, 0.7);
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}













/* notice */
.notice-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.notice-1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.notice-1-1-tit {
    padding: 6px 12px;
    background-color: rgba(237, 239, 241, 1);
    color: rgba(136, 136, 136, 1);
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.notice-1-1-tit.dark {
    background: #494848;
    color: #fff;
}

.notice-2 {
    width: 100%;
    height: 250px;
    border-bottom: 10px solid rgba(246, 246, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.notice-2.dark {
    border-bottom: 10px solid #242424;
}

.notice-2-1 {
    width: 296px;
    height: 173px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.161);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.notice-2-1.dark {
    background-color: #242424;
    color: #dddbdb;
    box-shadow: 1px 1px 5px 2px rgb(255 255 255 / 30%);
}

.notice-2-1-ico {
    width: 40px;
    height: 40px;
    color: rgba(112, 112, 112, 1);
}

.notice-2-1-ico.dark {
    color: rgb(196, 195, 195);
}

.notice-2-1-ico-1 {
    width: 10px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.notice-2-1-ico-1 .cls-1 {
    fill: #5e5e5e;
}

.notice-2-1-ico-1.dark .cls-1 {
    fill: #b6b3b3;
}

.notice-3 {
    margin-top: 30px;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 70px;
}

.notice-3-1 {
    font-size: 20px;
    margin-bottom: 20px;
}










/* reward */
.reward-box {
    margin-top: 25px;
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.reward-box.dark {
    color: #ddd;
}

.reward-box-1 {
    width: 200px;
    display: inline-grid;
    gap: 13px;
    padding: 16px;
    border: 1px solid #dddddd;
    border-radius: 15px;
}

.reward-box-1.dark {
    border: 1px solid #3f3e3e;
}

.reward-box-1-tit {
    font-size: 13px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.reward-box-1-txt {
    text-align: right;
    width: 100%;
    /* opacity: .5; */
}

.reward-box-1-txt-1 {
    font-size: 22px;
    font-weight: 700;
}

.reward-box-1-txt-2 {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
}

.reward-box-1-txt-2 svg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.green {
    color: #24ae64 !important;
}

.red {
    color: crimson !important;
}

.right {
    justify-content: right !important;
    text-align: right !important;
    padding-right: 12px !important;
    padding-left: 8px !important;
    box-sizing: border-box;
}

.left {
    justify-content: left !important;
    text-align: left !important;
    padding-right: 8px !important;
    padding-left: 12px !important;
    box-sizing: border-box;
}








.buy-modal {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 300px;
    max-width: 700px;
    /* height: 60px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 15px;
    padding: 15px 20px;
    z-index: 100;
}

.buy-modal.dark {
    color: #121212;
}

.buy-modal.copy {
    border: 1px solid rgba(0, 9, 3, .08);
    background-color: #fff;
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
    animation: Modal_opacityIn .4s ease-in-out;
}

.buy-modal.copy.dark {
    border: 1px solid #cccccc16;
    background-color: #2b2c37;
    color: #fff;
    animation: Modal_opacityIn .4s ease-in-out;
}

@keyframes Modal_opacityIn {
    from {
        opacity: 0;
        transform: translate(-50%, -20px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0px);
    }
}


.input-box {
    padding: 12px 15px !important;
}

.input-btn{
    padding: 5px 10px;
    background: rgba(239, 239, 239, 1);
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
}